import Hero from "../components/home/Hero";

const Home = () => {
  return (
    <>
      <Hero />
    </>
  );
};
export default Home;
